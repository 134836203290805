<template>
  <div>
        <el-card :span="200">
          <el-table :data="issuedQuotes" :span="200">
            <el-table-column prop="id" label="ID" width="50"></el-table-column>
            <el-table-column prop="quoteFrom" :label="$t('msg.rate.quoteFrom')" align="center"></el-table-column>
            <el-table-column prop="quoteTo" :label="$t('msg.rate.quoteTo')" align="center"></el-table-column>
            <el-table-column prop="quoteType" :label="$t('msg.rate.quoteType')" align="center"></el-table-column>
            <el-table-column prop="csopName" :label="$t('msg.rate.csopName')" align="center"></el-table-column>
            <el-table-column prop="createTime" :label="$t('msg.rate.createTime')" align="center"></el-table-column>
            <el-table-column prop="l2Owner" :label="$t('msg.rate.l2Owner')" align="center"></el-table-column>
            <el-table-column prop="l2Tag" :label="$t('msg.rate.l2Tag')" align="center"></el-table-column>
            <el-table-column fixed="right" :label="$t('msg.rate.Operations')" align="center" width="150px">
              <template #default="scope">
                <el-button link type="text" size="small" @click="pdf(scope.row)">pdf</el-button>
                <el-button link type="text" size="small" @click="csv(scope.row)">csv</el-button>
                <el-button link type="text" size="small" @click="toOrder(scope.row)" v-if="scope.row.quoteType==='Dray'">Book</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'

import axios from 'axios'
import { useRouter } from 'vue-router'
export default defineComponent({
  name: 'quotes',
  setup() {
    const issuedQuotes = ref()
    const router = useRouter()
    const curUser = localStorage.getItem('curUser')
    onMounted(() => {
      const param = 'curName=' + curUser
      const reqURL = process.env.VUE_APP_BASE + '/ocean/getl3QuoteList'
      axios.post(reqURL, param).then((response) => {
        issuedQuotes.value = response.data
      }).catch((response) => {
        console.log(response)
      })
    })
    const csv = (row) => {
      const url = process.env.VUE_APP_BASE + '/ocean/l3QuoteCSV?curUser=' + row.csopName + '&newId=' + row.id + '&quoteType=' + row.quoteType
      window.open(url)
    }
    const pdf = (row) => {
      const url = process.env.VUE_APP_BASE + '/ocean/l3QuotePDF?curUser=' + row.csopName + '&newId=' + row.id + '&quoteType=' + row.quoteType
      window.open(url)
    }
    const toOrder = (row) => {
      sessionStorage.setItem('quoteRecord', JSON.stringify(row))
      const orderTag = 'curUser=' + row.csopName + '&newId=' + row.id + '&quoteType=' + row.quoteType
      if (row.l2Tag.startsWith('DrayBatchQuote')) {
        router.push(`/dray/getQuotes/${orderTag}`)
      } else {
        return false
      }
    }
    return { issuedQuotes, csv, pdf, toOrder }
  }
})
</script>
